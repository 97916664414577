import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';
import { Notification } from '@interfaces/Notification';

export const putNotificationViewedReq = async (idNotification:number |string): Promise<any | undefined> => {
  try {
    const response = await httpConfig.fetch.put(`/api/notifications`,{},{
      params:{
        idNotification
      }
    });

    const notificationRes = response.data;
    logger.debug('notificationRes:', notificationRes);

    return notificationRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
};

interface getNotificationsProps {
  has_viewed?: boolean
  type?: number
}


export const getNotificationsReq = async (props?:getNotificationsProps): Promise<Notification[] | undefined> => {
  try {
    const response = await httpConfig.fetch.get(`/api/notifications`,{
      params:props
    });
    
    const notificationRes = response.data;
    logger.debug('notificationRes:', notificationRes);

    return notificationRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
};