import { getNotificationsReq } from '@services/notifications';
import axios from 'axios';
import { prizeToast } from './toast';

const fetchWithInterceptor = axios.create();

const fetch = axios.create();

// Add a response interceptor
fetchWithInterceptor.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data && response.data.has_prize) {
      getNotificationsReq({has_viewed:false,type:0}).then((notifications) => {
        if (notifications && Array.isArray(notifications)) {
          prizeToast({
            notifications:notifications
          });
        }
      });
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {
  fetchWithInterceptor,
  fetch,
};
