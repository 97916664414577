import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

interface ButtonActionProps {
  children: React.Component | any;
  onClick(): any;
  disabled?: boolean;
  id?: string
}

export default function ButtonAction({
  children,
  onClick,
  disabled,
  id,
}: ButtonActionProps) {
  const isDisabled = disabled == undefined ? false : disabled == true;
  return (
    <Flex
    id={id}
    as={motion.div}
    dragConstraints={{ left: -100, right: 100 }}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.9 }}
    transition='0.05s linear'
    width="-webkit-fill-available"
  >
    <Button
      color="black"
      minW="100%"
      bgGradient={'linear-gradient(91.51deg, #f7e064 -6.52%, #f0b716 121.12%)'}
      boxShadow={isDisabled?"base":"md"}
      size="md"
      disabled={isDisabled}
      onClick={() => onClick()}
      borderRadius={200}
      _hover={{ bg: "linear-gradient(91.51deg, #f7e064 -6.52%, #edcd75 121.12%)" }}
      _active={{ bg: "linear-gradient(91.51deg, #f7e064 -6.52%, #d79f05 121.12%)" }}
    >
      {children}
    </Button>
  </Flex>
  );
}
