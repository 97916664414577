import { ToastId } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import PrizeModal from '@components/PrizeModal';
import { Notification } from '@interfaces/Notification';

const { toast } = createStandaloneToast();

interface customToastProps {
  notifications: Notification[];
}

export const prizeToast = ({
  notifications
}: customToastProps) => {
  if (notifications.length> 0 && !toast.isActive(notifications[0].idNotification)) {
    toast({
      id: notifications[0].idNotification,
      position: 'top-right',
      render: (props) => {
        const handleOnClick = () => {
          toast.close(props.id as ToastId);
        };
        props.onClose = () => {
          handleOnClick();
        };
          return (
            <PrizeModal
              showModal={true}
              notifications={notifications}
              onClickOk={handleOnClick}
            />
          );
      },
      duration: null,
    });
  }
};

export default {
  toast,
};
