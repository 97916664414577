import { Box, Flex } from '@chakra-ui/react';

export default function LayoutWarnMe({ children }: { children: any }) {
  return (
    <Box minH="100vh" bgColor="rgba(31,25,96,1)">
      <Flex align={'center'} justify={'center'}>
        <Box rounded={'lg'} p={4}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
}
