import { CheckPhonePin, PhonePin } from '@interfaces/Phone';
import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';


/**           POST SEND PIN PHONE INIT     */

interface sendPinByPhoneReqProps {
  phone: string;
}

export const sendPinByPhoneReq = async ({
  phone,
}: sendPinByPhoneReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(
      `/api/users/panamamasmovil/pin`,
      { userPhone: phone },
    );

    const sendPinByPhoneRes = response.data;
    logger.debug('sendPinByPhoneRes:', sendPinByPhoneRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return sendPinByPhoneRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           POST SEND PIN PHONE END     */

/**           GET CHECK PIN PHONE INIT     */

interface checkPinByPhoneReqProps {
  userPhone: string;
  pin: string;
}

export const checkPinByPhoneReq = async ({
  userPhone,
  pin,
}: checkPinByPhoneReqProps): Promise<CheckPhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(
      `/api/users/panamamasmovil/checkpin`,
      { pin, userPhone }
    );

    const checkPinByPhoneRes = response.data;
    logger.debug('checkPinByPhoneRes:', checkPinByPhoneRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return checkPinByPhoneRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByPhoneReq:" + error);
    return undefined;
  }
};

/**           GET CHECK PIN PHONE END     */

/**           GET USER Email     */

export const checkUserExistByEmailReq = async (
  email:String): Promise<boolean> => {
  try {
    const response = await httpConfig.fetch.post(`/api/auth/get-user-by-email`, { email: email });

    const checkUserExistByEmailRes = response.data;
    logger.debug('checkUserExistByEmailRes:', checkUserExistByEmailRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return false;
    }
    return true;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return false;
  }
};

/**           POST SEND PIN END     */

/**           POST SEND PIN INIT     */

interface sendPinByUserIdReqProps {
  userId: number;
  phone: string;
}

export const sendPinByUserIdReq = async ({
  userId,
  phone,
}: sendPinByUserIdReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/${userId}/pin`, {
      body: JSON.stringify({ msisdn: phone }),
    });

    const sendPinByUserIdRes = response.data;
    logger.debug('sendPinByUserIdRes:', sendPinByUserIdRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return sendPinByUserIdRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           POST SEND PIN END     */

/**           GET CHECK PIN INIT     */

interface checkPinByUserIdReqProps {
  userId: number;
  pin: string;
}

export const checkPinByUserIdReq = async ({
  userId,
  pin,
}: checkPinByUserIdReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/${userId}/checkpin`, {
      body: JSON.stringify({ code: pin }),
    });

    const checkPinByUserIdRes = response.data;
    logger.debug('checkPinByUserIdRes:', checkPinByUserIdRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return checkPinByUserIdRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           GET CHECK PIN END     */

/**           PUT USER PHONE INIT     */

interface updateUserPhoneReqProps {
  userId: number;
  phone: string;
}

export const updateUserPhoneReq = async ({
  userId,
  phone,
}: updateUserPhoneReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.put(`/api/auth/put-user-by-id?id=${userId}`, {
      body: JSON.stringify({ phone }),
    });

    const updateUserPhoneRes = response.data;
    logger.debug('updateUserPhoneRes:', updateUserPhoneRes);
    if (response.status !== 200) {
      logger.error('Erro ao salvar o telefone, por favor tente novamente...');
      return undefined;
    }
    return updateUserPhoneRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           PUT USER PHONE END     */

export const sendWarnMeReq = async ({
  email
}: {email:string}): Promise<{message:string,timestamp:string} | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/warnme`, {
      email,
    });

    const sendWarnMeRes = response.data;
    logger.debug('sendWarnMeRes:', sendWarnMeRes);
    if (response.status !== 201) {
      logger.error('Erro ao enviar o email, por favor tente novamente...');
      return undefined;
    }
    return sendWarnMeRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           PUT USER PHONE END     */

/**           PUT USER EMAIL PHONE INIT     */

interface updateUserReqProps {
  userId: number;
  phone?: string;
  email?: string;
  cpf?: string;
  cnpj?: string;
  name?: string;
  bithday?: string;
}

export const updateUserReq = async ({
  userId,
  phone,
  email,
  cpf,
  cnpj,
  name,
}: updateUserReqProps): Promise<boolean | undefined> => {
  try {
    let body;
    if (phone != undefined || email != undefined) {
      if (phone != undefined) {
        phone = phone.replaceAll('(', '');
        phone = phone.replaceAll(')', '');
        phone = phone.replaceAll('-', '');
        phone = phone.replaceAll(' ', '');
      }
      if (cpf != undefined) {
        cpf = cpf.replaceAll('.', '');
        cpf = cpf.replaceAll('-', '');
        cpf = cpf.replaceAll(' ', '');
      }
      if (cnpj != undefined) {
        cnpj = cnpj.replaceAll('.', '');
        cnpj = cnpj.replaceAll('-', '');
        cnpj = cnpj.replaceAll('/', '');
        cnpj = cnpj.replaceAll(' ', '');
      }

      body = {
        msisdn: phone,
        cpf,
        cnpj,
        nome: name,
      };
    }
    const response = await httpConfig.fetchWithInterceptor.put(
      `/api/users/${userId}/update`,
      body
    );

    const updateUserRes = response.data;
    logger.debug('updateUserRes:', updateUserRes);
    if (response.status !== 201) {
      logger.error('Erro ao salvar o telefone, por favor tente novamente...');
      return undefined;
    }
    return true;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           PUT USER PHONE END     */