import pino from 'pino'

// create pino loggger
const levels = {
  http: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60,
};

const logger = pino({
  prettyPrint: true,
  browser: {asObject: true},
  customLevels: levels, // our defined levels
  useOnlyCustomLevels: true,  
  level: process.env.NEXT_PUBLIC_LOG_LEVEL || "info",
  base: {
    processes_str: JSON.stringify(process.versions),
    revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
  },
});


export default logger